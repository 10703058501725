import React from "react";
import "./FunPage.css";
import funPagePhotoOne from "../../accets/Images/funPagePhoto/funPagePhotoOne.jpg";
import funPagePhotoTwo from "../../accets/Images/funPagePhoto/funPagePhotoTwo.jpg";

const FunPage = () => {
  return (
    <div className="funPagePhoto_parent_div">
      <div className="funPagePhoto_div">
        <img className="funPagePhoto_one" src={funPagePhotoOne} alt="" />
        <img className="funPagePhoto_two" src={funPagePhotoTwo} alt="" />
      </div>
      <div className="funPage_text_div">
        <p>জী, আমিও তাবিজ বিক্রির চেষ্টায় আছি :)</p>
        <p>
          মেলা দিন কামলা দিয়েছি, এখনো দিচ্ছি । এখন মনে হচ্ছে - নাহ! তাবিজ টাবিজ
          বিক্রি না করলে জীবনটাই বৃথা। মানুষের দেখাদেখি একটা বইও লিখে ফেলেছি
          অনেক খাতা কলম নষ্ট করে - যদি কোন গতি হয়! মানে সফট কপি আর কি। অনেক
          চেষ্টা-তদবির চলিতেছে হার্ড কপি ছাপানোর।
        </p>
        <p>
          এখন তো দেখি তাবিজ বিক্রেতাদের আবার সবাই ভালো চোখে দেখে না। তাবিজ
          বিক্রেতাদেরও কোয়ালিটির কিছু ব্যাপার আছে অবশ্য - নইলে পাবলিক তো আর
          খামাখা দোষ দেয় না!
        </p>
        <p>আমার তাবিজটা ট্রাই করে দেখতে পারেন - যদি লাইগ্যা যায়!</p>
      </div>
    </div>
  );
};

export default FunPage;
