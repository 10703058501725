import React, { useState } from "react";
import "./UserBuying.css";
import { Link, useLoaderData } from "react-router-dom";
import UseBuyingDetails from "../UseBuyingDetails/UseBuyingDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import NastedLoop from "../NastedLoop/NastedLoop";
import axios from "axios";

const UserBuying = () => {
  const [feedback, setFeedback] = useState("");
  const [visibleDetails, setVisibleDetails] = useState({});

  const userBuyingGetApi = useLoaderData();

  console.log(userBuyingGetApi);
  if (!userBuyingGetApi.data) {
    return <div>Loading...</div>; // You can show a loading indicator or message while data is being fetched
  }

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the feedback submission (you can send it to a server or perform other actions)
    console.log("Feedback submitted:", feedback);
    // Reset the feedback field
    setFeedback("");
  };

  // const showData = (data) => {
  //   console.log(data);
  //   const arrowData = document.getElementById("arrow_data");
  //   arrowData.data.setAttribute("hidden", "hidden");
  // };

  const toggleDetails = (id) => {
    setVisibleDetails((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  const rutUrl = "https://app.teacherjackonline.com";

  // const downloadPdfFile = (url) => {
  //   const pdfDownloadBtn = document.getElementById("pdfDownloadBtn");
  //   const fileName = url.split("/").pop();
  //   const aTag = document.createElement("a");
  //   aTag.href = url;
  //   aTag.setAttribute("download", fileName);
  //   pdfDownloadBtn.appendChild(aTag);
  //   aTag.click();
  //   aTag.remove();
  // };

  return (
    <div className="useBuyingDetails_parent_div mt-10">
      <h1 className="my_buying">My Purchase</h1>
      <table className="table-border table" cellPadding={30}>
        <thead>
          <tr className="t_head">
            <th>Product Name</th>
            <th>Type</th>
            <th>Payment From</th>
            <th>Pay Method</th>
            <th>Price</th>
            <th>Discount</th>
            <th>Status</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {/* <div>
            {userBuyingGetApi.data.map((data) => (
              <tr className="table_colum" key={data.id}>
                <td>{data.product_name}</td>
                <td>{data.payee_number}</td>
                <td>{data.pay_method}</td>
                <td>{data.price}</td>
                <td>{data.discount}</td>

                <td>
                  {data.url ? (
                    <Link target="_blank" className="btn" to={data.url}>
                      Download
                    </Link>
                  ) : (
                    ""
                  )}
                </td>

                <td
                  className={
                    data.status === "Approved" ? "green-status" : "red-status"
                  }
                >
                  {data.status}
                </td>
              </tr>
            ))}
          </div> */}

          {/* ============================== */}
          {/* =========== New API ========== */}
          {/* ============================== */}

          {userBuyingGetApi.data.data.map((data) => (
            <React.Fragment key={data.id}>
              <tr className="table_colum">
                <td>{data.product.name}</td>
                <td>{data.product.type}</td>
                <td>{data.payee_number}</td>
                <td>{data.pay_method}</td>
                <td>{data.price}</td>
                <td>{data.discount}</td>

                <td
                  className={
                    data.status === "Approved" ? "green-status" : "red-status"
                  }
                >
                  {data.status}
                </td>
                <td>
                  {data.resource ? (
                    <button>
                      <Link
                        target="_blank"
                        className="btn"
                        to={`${rutUrl}${data.resource.content}`}
                        download
                      >
                        Download
                      </Link>
                    </button>
                  ) : (
                    <button
                      onClick={() => toggleDetails(data.id)}
                      className="btn"
                    >
                      <FontAwesomeIcon icon={faAngleDown} />
                    </button>
                  )}
                </td>
              </tr>
              {visibleDetails[data.id] && (
                <tr key={`arrow_data_${data.id}`} className="arrow_data">
                  <td colSpan={8}>
                    <table style={{ width: "100%" }}>
                      <tbody className="tbody">
                        {data.module !== null ? (
                          <React.Fragment>
                            <tr>
                              <td colSpan={8}>{data.module.name}</td>
                            </tr>

                            {data.module.resource.map((rsData) => (
                              <React.Fragment key={rsData.id}>
                                <tr>
                                  <td
                                    style={{
                                      width: "20%",
                                      // backgroundColor: "red",
                                    }}
                                  >
                                    {rsData.name}
                                  </td>
                                  <td>
                                    <button
                                      id="pdfDownloadBtn"
                                      className="btn second_btn"
                                    >
                                      <a
                                        target="blank"
                                        href={`${rutUrl}${rsData.content}`}
                                        // href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                                        // href="https://sazzad.com/fileName.pdf"
                                        download={rsData.content}
                                      >
                                        Download
                                      </a>
                                    </button>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <tr className="fff">
                              <td className="tdtd">
                                <tr className="trtrtr">
                                  <td
                                    className="first_text_package"
                                    colSpan={8}
                                  >
                                    {data?.package?.name}
                                  </td>
                                </tr>
                                {data?.package?.module?.map((data) => (
                                  <React.Fragment key={data.id}>
                                    <NastedLoop data={data} />
                                  </React.Fragment>
                                ))}
                              </td>
                            </tr>
                          </React.Fragment>
                        )}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserBuying;
