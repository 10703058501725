import React, { useState } from "react";
import "./NastedLoop.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const NastedLoop = ({ data }) => {
  console.log(data);
  const [visibleDetails, setVisibleDetails] = useState({});

  const toggleDetails = (id) => {
    setVisibleDetails((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle visibility for the specific id
    }));
  };
  const rutUrl2 = "https://app.teacherjackonline.com";
  return (
    <React.Fragment>
      <tr className="flex_tr">
        <td>{data.name}</td>
        <td>
          <button
            onClick={() => toggleDetails(data.id)}
            className="btn arrow_btn"
          >
            <FontAwesomeIcon icon={faAngleDown} />
          </button>
        </td>
      </tr>

      {visibleDetails[data.id] && (
        <tr>
          <td>
            {data.resource.map((pckData) => (
              <tr key={pckData.id} className="nasted_tr">
                <td>{pckData.name}</td>
                <td>
                  <button className="btn third_btn">
                    {" "}
                    <a
                      target="blank"
                      href={`${rutUrl2}${pckData.content}`}
                      download="Icons.pdf"
                    >
                      Download
                    </a>
                  </button>
                </td>
              </tr>
            ))}
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default NastedLoop;
